@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
    url('../public/fonts/SFProDisplay-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../public/fonts/SFProDisplay-UltralightItalic.woff2')
      format('woff2'),
    url('../public/fonts/SFProDisplay-UltralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #d2ab66;
}

:root {
  font-family: 'SF Pro Display', sans-serif;
}

input,
button {
  font: inherit;
}

.toaster-container {
  min-width: 320px;
  width: auto;
}

.toaster-container > div {
  padding: 0;
  border-radius: 8px;
}

.toaster-container > div > div {
  padding: 0;
  margin: 0;
}

.Toastify__toast-body > div {
  height: 100%;
}
